<template>
  <div id="enterpriseField">
    <div class="e-a-title">招商分析大屏</div>
    <div class="e-a-box" v-loading="loading">
      <div class="e-a-b-title">请选择八个领域，已选择<span>{{fieldNum}}</span>个领域</div>
      <div class="e-f-checkbox">
        <el-checkbox-group v-model="invest_click" @change='investChange' :max='8'>
          <el-checkbox v-for="item in invest_industry" :label="item.industry_id" :key="item.industry_id" @change='investClick(item.industry_id)'>{{item.industry_name}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="e-a-sec-box" v-if="invest_sec_data.length>0">
        <div class="e-a-b-title">二级筛选</div>
        <div class="e-a-sec-div" v-for="(item,index) in invest_sec_data" :key="index">
          <el-form class="e-f-checkbox" label-width="130px">
            <el-form-item :label="item.industry_name" class="e-f-c-form">
              <el-checkbox :indeterminate="item.isIndeterminate" checked :v-model="item.checkAll" @change="checked=>handleCheckAllChange(checked,index)" class="e-f-allCheck">全选</el-checkbox>
              <el-checkbox-group v-model="item.childrenSelect" @change="value=>handleCheckChange(value,index)">
                <template v-for="(child,i) in item.children">
                  <el-checkbox v-if="i < item.showIndex" :label="child.industry_id" :key="child.industry_id">{{child.industry_name}}</el-checkbox>
                </template>
                <el-button
                  v-if="item.showIndex === item.children.length"
                  @click="showClickOff(index)"
                  size="mini"
                  style="margin-left: 8%"
                  icon="el-icon-arrow-up"
                >收起</el-button>
                <el-button
                  v-else
                  @click="showClickOff(index)"
                  size="mini"
                  style="margin-left: 8%"
                  icon="el-icon-arrow-down"
                >展开</el-button>                
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-button type="primary" @click="goBigScreen" class="e-f-btn">查看大屏</el-button>
      <el-button type="primary" @click="$router.push('/enterpriseAnalysis')" class="e-f-btn">取消</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fieldNum: 0,
      pid: 0,
      user_id: localStorage.user_id?localStorage.user_id:'',
      data_type: 'zst',
      organ_id: sessionStorage.organizationId,
      invest_industry: [],//一级菜单数据
      invest_click: [],//选中的一级菜单
      invest_sec_data: [],//选中的一级和二级项
      loading: false
    }
  },
  methods: {
    // 筛选展开合并
      showClickOff (i) {
        if (this.invest_sec_data[i]['showIndex'] === this.invest_sec_data[i]['children'].length) {
          this.invest_sec_data[i]['showIndex'] = 10;
        } else {
          this.invest_sec_data[i]['showIndex'] = this.invest_sec_data[i]['children'].length;
        }
      },
    // 全选
      handleCheckAllChange(checked,index) {
        this.invest_sec_data[index]['childrenSelect'] = []
        if(checked){
          for (let i in this.invest_sec_data[index]['children']) {
            this.invest_sec_data[index]['childrenSelect'].push(this.invest_sec_data[index]['children'][i].industry_id)
          }
        }
        this.invest_sec_data[index].isIndeterminate = false
      },
    // 不全选
      handleCheckChange(value,index) {
        this.invest_sec_data[index].checkAll = value.length === this.invest_sec_data[index]['children'].length
        this.invest_sec_data[index].isIndeterminate = value.length > 0 && value.length < this.invest_sec_data[index]['children'].length
      },
    // 获取一级领域数据
      getFirst_invest(pid) {
        let that = this;
        this.getInvesttag(0).then(res => {
          if (res.data.status_code === 2000000) {
            let data = res.data.data.default;
            data.map((v,i)=>{
              v.children = [];//自己的二级菜单
              v.childrenSelect = []//自己二级菜单被选中
              v.showIndex = 10// 二级菜单展示个数
              v.checkAll = false//二级菜单时候全选
              v.isIndeterminate = false//二级菜单全选样式
            })
            that.invest_industry = data;
            // console.log(that.invest_industry)
          } else {
            // 错误提示
            error(res.data.error_msg);
          }
        });
      },
    // 二级领域
      investChange(e) {
        this.fieldNum = this.invest_click.length
        this.invest_sec_data = []
        for(let i in this.invest_click){
          for(let j in this.invest_industry){
            if(this.invest_click[i] == this.invest_industry[j].industry_id){
              this.invest_sec_data.push(this.invest_industry[j])
            }
          }
        }
        // console.log(this.invest_sec_data)
      },
    // 点击一级领域添加二级领域的id
      investClick(id) {
        for (let i = 0; i < this.invest_industry.length; i++) {
          if(id == this.invest_industry[i].industry_id && this.invest_industry[i].children.length == 0){
            this.getInvesttag(id).then(res => {
              if (res.data.status_code === 2000000) {
                let d = []
                for(let i in res.data.data.default){
                  d.push(res.data.data.default[i].industry_id)
                }
                this.invest_industry[i].children = res.data.data.default//将获取到的二级菜单加入到一级的children中保存
                this.invest_industry[i].childrenSelect = d//将获取到的二级菜单全选
              }else{
                error(res.data.error_msg);
              }
            })
          }
        }
        // console.log(this.invest_industry)
      },   
    // 获取最新获投 领域标签
      getInvesttag(pid) {
        let organ_id = this.organ_id,
          user_id = this.user_id;
        return this.$http
          // .get('http://my.webapi.weitianshi.com.cn/' + this.URL.industry, {
          .get('https://wts.weitianshi.cn/' + this.URL.industry, {
            params: {
              data_type: this.data_type,
              organ_id,
              user_id,
              pid
            }
          })
          .then(res => {
            return res;
          })
          .catch(err => {
            // console.log(err);
          });
      },
    // 跳转大屏
      goBigScreen() {
        if(this.invest_sec_data.length<8){
            this.$message({
              message: '选择的领域少于8个',
              type: 'warning'
            });
        }else{
          this.loading = true
          let industryTotal = []
          for(let i in this.invest_sec_data){
            industryTotal.push(this.invest_sec_data[i].industry_id)
            industryTotal = industryTotal.concat(this.invest_sec_data[i].childrenSelect)
          }
          let obj = {
            industry: industryTotal,
            data_type: this.data_type,
            organ_id: this.organ_id,
          }
          // this.$http.post('http://my.webapi.weitianshi.com.cn/' + this.URL.adminUrl.getScreenRange, obj).then(r => {
          this.$http.post('https://wts.weitianshi.cn/' + this.URL.adminUrl.getScreenRange, obj).then(r => {
            this.loading = false
            this.$router.push({
              path:'/xiaoShanInvestment',
              query:{ 
                data_type: this.data_type,
                organ_id: this.organ_id, 
                range_id:r.data.data[0].id
              }
            })
          }).catch(err => {
            this.$message({
              message: err.message,
              type: 'warning'
            });          
          })        
        }
      }    
  },
  created() {
    this.getFirst_invest();
  }
}
</script>
<style lang="scss" scoped>
  .e-a-title{
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  .e-a-box{
    margin: 0 20px;
    .e-a-b-title{
      margin: 20px 0;
      font-size: 16px;
    }
  }
  .e-f-btn{
    margin-top: 30px;
  }
  .e-f-checkbox{
    .el-checkbox{
      line-height: 25px;
      width: 10%;
    }
  }
</style>
<style lang="scss">
  #enterpriseField{
    .e-f-checkbox{
      .el-form-item{
        .el-form-item__label{
          line-height: 25px;
        }
      }
    }
    .e-f-c-form{
      position: relative;
      .e-f-allCheck{
       position: absolute;  
       left: 0;
      }
      .el-checkbox-group{
        .el-checkbox:first-child{
          margin-left: 10%;
        }
      }
    }
  }
</style>